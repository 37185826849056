'use client';

import * as React from 'react';
import { Label } from '../Form';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
import type { UIComponentProps, UIThemableComponent } from '../types/UI';
import { UIColors, UIShapes, UISizes, UIVariants } from '../types/UI';
import { cn } from '../utils/classnames';

export type CheckboxNativeCheckedState = boolean;

export type CheckboxNativeProps = UIThemableComponent<UIComponentProps, HTMLInputElement> & {
  ref?: React.ForwardedRef<HTMLInputElement>;

  indeterminate?: boolean;
  initialChecked?: boolean;
  labelAppend?: React.ReactNode;
  labelPrepend?: React.ReactNode;
  disabled?: boolean;
  tabIndex?: number;
  helpText?: React.ReactNode;
  checked?: CheckboxNativeCheckedState;
  labelClasses?: string;
  onCheckedChange?: (checked: boolean) => void;
};

const CheckboxNativeComp = (
  {
    cname = 'ui-checkbox',
    className,

    // ----- Themable props defaults
    color = UIColors.primary,
    size = UISizes.xs,
    variant = UIVariants.solid,
    shape = UIShapes.rounded,
    gradient,
    elevation,

    // ----- options
    id,
    tabIndex = 1,
    disabled,
    checked,
    initialChecked,
    labelAppend,
    labelPrepend,
    labelClasses,
    helpText,
    children: _children,

    onCheckedChange,
    ...otherProps
  }: CheckboxNativeProps,
  forwardedRef: React.ForwardedRef<HTMLInputElement>
) => {
  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, theme, gradient, elevation };

  // support for uncontrolled component usage
  const [unControlledValue, setUnControlledValue] = React.useState<CheckboxNativeCheckedState>(initialChecked || false);
  const controlled = initialChecked === undefined;

  const [internalChecked, setInternalChecked] = React.useState<boolean>(initialChecked || !!checked);

  const handleChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const checked = ev.target.checked;
      controlled ? setInternalChecked(checked) : setUnControlledValue(checked);
      onCheckedChange?.(checked);
    },
    [controlled, onCheckedChange]
  );

  // remove component from tabindex when it is disabled
  const _tabIndex = React.useMemo(() => {
    if (disabled) return -1;
    return tabIndex;
  }, [disabled, tabIndex]);

  React.useEffect(() => {
    setInternalChecked(!controlled ? unControlledValue : !!checked);
  }, [controlled, unControlledValue, checked]);

  return (
    <Label disabled={disabled} htmlFor={id} className={cn('inline-flex gap-2 align-middle')}>
      {labelPrepend && (
        <div>
          <div className={cn('align-middle', `${labelClasses}`)}>{labelPrepend}</div>
          <div className={cn('text-xs')}>{helpText}</div>
        </div>
      )}
      <div
        className={cn(
          'flex items-center',
          { 'h-6': size === UISizes.xs },
          { 'h-6': size === UISizes.sm },
          { 'h-6': size === UISizes.md },
          { 'h-6': size === UISizes.lg },
          { 'h-6': size === UISizes.xl }
        )}
      >
        <input
          id={id}
          type="checkbox"
          ref={forwardedRef}
          disabled={disabled}
          tabIndex={_tabIndex}
          checked={internalChecked}
          onChange={handleChange}
          className={
            variant === UIVariants.unstyled
              ? cname
              : cn(
                  cname,
                  UIStyling.shapes(themableProps),
                  UIStyling.textSizes(themableProps),
                  UIStyling.squareSizesForm(themableProps),
                  // mark this as a peer element that could be associated with a sibling
                  // see https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-sibling-state
                  `peer cursor-pointer align-middle`,
                  `shrink-0 appearance-none bg-center bg-no-repeat`,

                  // control svg icon sizing for squared buttons (they can just take up full size since will be no text in this mode)
                  '[&>span>svg]:h-full [&>span>svg]:w-full',

                  // disabled styling
                  `disabled:cursor-not-allowed disabled:opacity-50`,

                  {
                    [`rounded`]: shape === UIShapes.rounded,
                    [`dark:border-default-600`]: !internalChecked,
                    [`dark:bg-default-700`]: !internalChecked && variant === UIVariants.solid,
                    [`${cname}--checked bg-${color}`]: internalChecked,

                    [`border border-${color}`]: variant !== UIVariants.ghost,

                    // control svg icon sizing for non squared buttons
                    ['[&>span>svg]:h-4 [&>span>svg]:w-4']: size == UISizes.xs,
                    ['[&>span>svg]:h-5 [&>span>svg]:w-5']: size == UISizes.sm,
                    ['[&>span>svg]:h-6 [&>span>svg]:w-6']: size == UISizes.md,
                    ['[&>span>svg]:h-8 [&>span>svg]:w-8']: size == UISizes.lg,
                    ['[&>span>svg]:h-10 [&>span>svg]:w-10']: size == UISizes.xl,
                  },

                  theme === 'flowbite' && {
                    'focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-default-800 focus:ring-2':
                      true,
                    [`${cname}--checked bg-${color}`]: internalChecked,
                  },

                  theme !== 'flowbite' && {
                    [`focus-visible:ring-ring focus-visible:outline-none focus-visible:ring-1`]: true,
                  },

                  className
                )
          }
          {...otherProps}
        />
      </div>
      {labelAppend && (
        <div className="flex flex-col justify-center">
          <div className={cn('align-middle', `${labelClasses}`)}>{labelAppend}</div>
          <div className={cn('text-xs')}>{helpText}</div>
        </div>
      )}
    </Label>
  );
};

export const CheckboxNative = React.forwardRef<HTMLInputElement, CheckboxNativeProps>(
  CheckboxNativeComp
) as React.ForwardRefExoticComponentExtended<CheckboxNativeProps>;
// Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export const CheckboxNativeVariants = [UIVariants.solid, UIVariants.outline];
export const CheckboxNativeShapes = [UIShapes.rounded];
