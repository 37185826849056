'use client';

import type { ImageFormat } from 'exif-rotate-js';
import React from 'react';
import type { AvatarEditorProps, ImageState, Position } from 'react-avatar-editor';
import ReactAvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { FormGroup } from '../Form';
import { Input } from '../Input';
import { Slider } from '../Slider';
import { LayoutTypes, type UIComponentProps, type UIThemableComponent } from '../types/UI';
import { getBlobUrlFromCanvas, resizeCanvas } from '../utils/canvas';
import { cn } from '../utils/classnames';

export type CropperResults = {
  zoom: number;
  rotation: number;
  position: Position | undefined;
  base64: string;
  objectUrl: string;
  type: string;
  width: number;
  height: number;
};

export type ImageCropperProps = UIThemableComponent<UIComponentProps, ImageCropperImperativeProps> &
  Omit<AvatarEditorProps, 'image' | 'ref'> & {
    ref?: React.Ref<ImageCropperImperativeProps>;
    image?: string;
    width?: number;
    height?: number;
    outputWidth?: number;
    outputHeight?: number;
    resize?: boolean;
    zoom?: number;
    hasDropzone?: boolean;
    hasZoom?: boolean;
    hasRotation?: boolean;
    hasCustomSize?: boolean;
    convertToType?: ImageFormat;
    onAccept?: (props: CropperResults) => void;
  };

export type ImageCropperImperativeProps = {
  getEditor: () => void;
  getImage: () => Promise<CropperResults | undefined>;
};

function ImageCropperComp(
  {
    cname = 'ui-image-cropper',
    className,
    border = 0,
    zoom = 1,
    rotate = 0,
    position,
    image,
    width,
    height,
    outputWidth,
    outputHeight,
    convertToType = 'image/jpeg',
    hasZoom,
    hasRotation,
    hasCustomSize,
    classNames,
    hasDropzone,
    resize = true,
    ref: _ref,
    ...otherProps
  }: React.PropsWithChildren<ImageCropperProps>,
  forwardedRef: React.ForwardedRef<ImageCropperImperativeProps>
): React.ReactElement {
  const [_width, setWidth] = React.useState<number>(width || 200);
  const [_height, setHeight] = React.useState<number>(height || 200);
  const [_zoom, setZoom] = React.useState<number>(zoom);
  const [_rotate, setRotate] = React.useState<number>(rotate);
  const [_position, setPosition] = React.useState<Position | undefined>(position);
  const [objectUrl, setObjectUrl] = React.useState<string>();
  const [imgInfo, setImgInfo] = React.useState<ImageState>();

  const [_image, setImage] = React.useState<File | string | undefined>(image);

  const editorRef = React.useRef<ReactAvatarEditor | null>(null);

  React.useImperativeHandle(forwardedRef, () => ({
    getEditor: () => editorRef.current,
    getImage,
  }));

  React.useEffect(() => {
    return () => {
      if (objectUrl) URL.revokeObjectURL(objectUrl);
      setObjectUrl(undefined);
    };
  }, [objectUrl]);

  React.useEffect(() => {
    setImage(image);
  }, [image]);

  const getImage = async () => {
    const canvas = editorRef?.current?.getImage();
    if (!canvas) return;

    const inputWidth = imgInfo?.width || 0;
    const inputHeight = imgInfo?.height || 0;

    let imageUrl;
    let base64;

    const _outputWidth = outputWidth ? outputWidth : _width || canvas.width;
    const _outputHeight = outputHeight ? outputHeight : _height || canvas.height;

    const requiresUpscaling = _outputWidth > inputWidth || _outputHeight > inputHeight;

    if (!resize || !requiresUpscaling) {
      imageUrl = await getBlobUrlFromCanvas(canvas);
      base64 = canvas?.toDataURL(convertToType);
    } else {
      const resizedCanvas = resizeCanvas(canvas, {
        width: outputWidth ? outputWidth : _outputWidth,
        height: outputHeight ? outputHeight : _outputHeight,
      });
      if (!resizedCanvas) return;
      imageUrl = await getBlobUrlFromCanvas(resizedCanvas);
      base64 = resizedCanvas?.toDataURL(convertToType);
    }

    return {
      zoom: _zoom,
      rotation: _rotate,
      position: _position,
      base64,
      objectUrl: imageUrl,
      // size: base64.length,
      width: _outputWidth,
      height: _outputHeight,
      type: convertToType,
    };
  };

  const handlePositionChange = (pos: Position) => {
    setPosition(pos);
  };

  const handleLoadSuccess = (info: ImageState) => {
    setImgInfo(info);
  };

  const cropper = (
    <>
      {_image ? (
        <ReactAvatarEditor
          ref={editorRef}
          image={_image}
          border={border}
          scale={_zoom}
          rotate={_rotate}
          crossOrigin="anonymous"
          position={_position}
          width={_width}
          height={_height}
          {...otherProps}
          style={{
            ...otherProps.style,
            width: _width + 'px',
            height: _height + 'px',
          }}
          onPositionChange={handlePositionChange}
          onLoadSuccess={handleLoadSuccess}
          className={cn('mx-auto', classNames?.canvas)}
        />
      ) : null}
      {(hasZoom || hasRotation) && (
        <div className={cn('my-4 space-y-2', 'controls', classNames?.controls)}>
          {hasCustomSize && (
            <div className="flex flex-row gap-4">
              <FormGroup classNames={{ label: 'w-14 text-sm' }} label="Width" layout={LayoutTypes.horizontal}>
                <Input value={_width + ''} onValueChange={value => setWidth(+value)} />
              </FormGroup>
              <FormGroup classNames={{ label: 'w-14 text-sm' }} label="Height" layout={LayoutTypes.horizontal}>
                <Input value={_height + ''} onValueChange={value => setHeight(+value)} />
              </FormGroup>
            </div>
          )}

          {hasZoom && (
            <FormGroup classNames={{ label: 'w-14 text-sm' }} label="Zoom" layout={LayoutTypes.horizontal}>
              <Slider min={1} max={3} step={0.1} value={[_zoom]} onValueChange={values => setZoom(values[0])} />
            </FormGroup>
          )}

          {hasRotation && (
            <FormGroup classNames={{ label: 'w-14 text-sm' }} label="Rotate" layout={LayoutTypes.horizontal}>
              <Slider min={0} max={360} step={1} value={[_rotate]} onValueChange={values => setRotate(values[0])} />
            </FormGroup>
          )}
        </div>
      )}
    </>
  );

  if (hasDropzone) {
    return (
      <div className={cn(cname, className)}>
        <Dropzone onDrop={dropped => setImage(dropped[0])} noClick noKeyboard>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {cropper}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }

  return <div className={cn(cname, className)}>{cropper}</div>;
}

export const ImageCropper = React.forwardRef<ImageCropperImperativeProps, ImageCropperProps>(
  ImageCropperComp
) as React.ForwardRefExoticComponentExtended<ImageCropperProps>;
