'use client';

import type { SwitchProps as OriginalSwitchProps } from '@radix-ui/react-switch';
import * as React from 'react';
import { Label } from '../Form';
import { Text } from '../Text/Text';
import type { UIThemableComponent } from '../types/UI';
import { UIColors, UIShapesExtra, UISizes, UIVariants } from '../types/UI';
import { cn } from '../utils/classnames';
import { Switch } from './Switch';

export type SwitchWithLabelElement = HTMLButtonElement;
export type SwitchWithLabelProps = OriginalSwitchProps &
  UIThemableComponent & {
    label: string;
    secondaryLabel?: string;
    showRequiredIndicator?: boolean;
  };

const SwitchWithLabelComp = (
  {
    className,
    // ----- Themable props defaults
    color = UIColors.default,
    size = UISizes.md,
    variant = UIVariants.solid,
    shape = UIShapesExtra.pill,
    gradient,
    colorShadows,
    elevation,
    id,
    label,
    secondaryLabel = '',
    showRequiredIndicator,
    ...props
  }: SwitchWithLabelProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) => {
  return (
    <div className="flex gap-2" ref={forwardedRef}>
      <Switch
        id={id}
        className={className}
        color={color}
        size={size}
        variant={variant}
        shape={shape}
        gradient={gradient}
        colorShadows={colorShadows}
        elevation={elevation}
        {...props}
      />
      <div className="flex flex-col gap-0.5">
        <Label htmlFor={id} className={cn({ [`after:content-['*']`]: showRequiredIndicator })}>
          {label}
        </Label>
        {secondaryLabel && (
          <Text as="div" size="sm">
            {secondaryLabel}
          </Text>
        )}
      </div>
    </div>
  );
};

export const SwitchWithLabel = React.forwardRef<HTMLDivElement, SwitchWithLabelProps>(
  SwitchWithLabelComp
) as React.ForwardRefExoticComponentExtended<SwitchWithLabelProps>;

export const SwitchWithLabelVariants = [UIVariants.solid, UIVariants.outline, UIVariants.unstyled];
