import React from 'react';
import type { Control, FieldValues, FormState, UseFormRegister } from 'react-hook-form';
import type { FormSchemaEntry } from './types';

export type FormContextProps<Values extends FieldValues> = {
  schema: FormSchemaEntry[];
  // eslint-disable-next-line
  formState: FormState<any>;
  // eslint-disable-next-line
  register: UseFormRegister<Values>;
  control: Control<Values, unknown>;
};

export const FormContext = React.createContext<FormContextProps<FieldValues> | null>(null);
