import { createImage } from './image';

export type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

export async function getBlobUrlFromCanvasUsingFetch(canvas: HTMLCanvasElement) {
  const base64 = canvas.toDataURL();

  const response = await fetch(base64);
  const blob = await response.blob();
  return window.URL.createObjectURL(blob);
}

export async function getBlobUrlFromCanvas(canvas: HTMLCanvasElement): Promise<string> {
  return new Promise(resolve => {
    canvas?.toBlob(blob => {
      if (!blob) return;
      const newImg = document.createElement('img');
      const url = URL.createObjectURL(blob);

      newImg.onload = () => {
        resolve(url);
      };

      newImg.src = url;
    });
  });
}

export async function generateCanvasImage(
  src: string,
  pixelCrop: Area,
  rotation = 0,
  mimeType = 'jpg'
): Promise<string | undefined> {
  const image = await createImage(src);
  if (!image) return;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  if (rotation) {
    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    return canvas.toDataURL(mimeType);
  }

  canvas.width = image.width;
  canvas.height = image.height;
  ctx.drawImage(image, image.width, image.height);

  return canvas.toDataURL(mimeType);
}

export function resizeCanvas(
  canvas: HTMLCanvasElement,
  {
    width,
    height,
  }: {
    width: number;
    height: number;
  }
) {
  const resizedCanvas = document.createElement('canvas');
  const resizedContext = resizedCanvas.getContext('2d');
  if (!resizedContext) return;
  resizedCanvas.width = width;
  resizedCanvas.height = height;

  resizedContext.drawImage(canvas, 0, 0, width, height);
  return resizedCanvas;
}
