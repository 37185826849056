import { endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, startOfYear, subMonths, subWeeks } from 'date-fns';

// date format used to validate dates against schema and to submit to backend
export const timeDataFormat = 'HH:mm';
export const timeDisplayFormat = 'h:mm a';

export const dateDisplayFormat = 'dd MMM yyyy';
export const dateTimeDisplayFormat = 'dd MMM yyyy HH:mm:ss';

export const timeDisplayNoMeridiemFormat = 'h:mm';
export const timeMeridiemOnlyFormat = 'a';

export const dateMonthFormat = 'yyyy-MM';
export const dateWeekFormat = `yyyy-'W'II`;
export const dateFormat = 'yyyy-MM-dd';

export const dateTimeFormat = `yyyy-MM-dd'T'HH:mm`;
export const dateTimeSecondsFormat = `yyyy-MM-dd'T'HH:mm:ss`;

/**
 * Formats the given date using the specified pattern.
 *
 * @param {Date} date - the date to format
 * @param {string} pattern - the pattern to use for formatting
 * @return {string} the formatted date string
 */
export function formatDate(date?: Date, pattern: string = dateDisplayFormat) {
  if (!date) return 'invalid date';
  try {
    return format(new Date(date), pattern);
  } catch (err) {
    console.error('formatDate Error', new Date(date), err);
  }
}

export function getLastWeekDates(currentDate: Date = new Date()) {
  const from = startOfWeek(subWeeks(currentDate, 1));
  const to = endOfWeek(subWeeks(currentDate, 1));

  return { from, to };
}

export function getLastMonthDates(currentDate: Date = new Date()) {
  const from = startOfMonth(subMonths(currentDate, 1));
  const to = endOfMonth(subMonths(currentDate, 1));

  return { from, to };
}

export function getMonthToDateDates(currentDate: Date = new Date()) {
  const from = startOfMonth(currentDate);

  return { from, to: currentDate };
}

export function getYearToDateDates(currentDate: Date = new Date()) {
  const from = startOfYear(currentDate);

  return { from, to: currentDate };
}
