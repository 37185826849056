'use client';

import type { HorVerLayout, UIThemableComponent } from '@wolfejs/ui/types/UI';
import { UIColors, UIShapes, UIVariants } from '@wolfejs/ui/types/UI';
import { cn } from '../utils/classnames';
import type { MenuItemType } from './types';

export type MenuItemProps = UIThemableComponent & {
  layout?: HorVerLayout;
  item: MenuItemType;
  isActive?: boolean;
  isDisabled?: boolean;
};

export const MenuItem = ({
  className,
  item,
  color = UIColors.default,
  variant = UIShapes.rounded,
  shape,
  isActive,
  isDisabled,
}: MenuItemProps) => {
  return (
    <div
      className={cn(
        'flex cursor-pointer flex-col items-start whitespace-nowrap p-2',
        { [`rounded-lg`]: shape === UIShapes.rounded },
        { [`text-white bg-${color}`]: variant === UIVariants.solid },
        { [`hover:bg-${color}-hover text-foreground dark:text-default-foreground`]: variant !== UIVariants.unstyled },
        { ['bg-default-200 dark:bg-default-700']: isActive },
        {
          ['hover:bg-default-300 hover:dark:bg-default-800']:
            variant !== UIVariants.unstyled && color === UIColors.default,
        },
        { ['cursor-not-allowed']: isDisabled },
        className
      )}
    >
      <div className="flex">
        {item.icon && (
          <div
            className={cn(
              'mr-2 flex h-6 w-6 items-center justify-center'
              // `text-default-400 dark:text-default-foreground`
            )}
          >
            {item.icon}
          </div>
        )}
        {item.text && <span>{item.text}</span>}
      </div>
      {item.helperText && <div className="text-default-500 text-sm font-normal">{item.helperText}</div>}
    </div>
  );
};
