'use client';

import { PlusIcon, TrashIcon, UploadIcon } from '@radix-ui/react-icons';
import { EyeIcon } from '@wolfejs/icons/IconMap';
import { cn } from '@wolfejs/ui/utils/classnames';
import React from 'react';
import Dropzone from 'react-dropzone';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import { Box } from '../Box';
import { Button } from '../Button';
import type { CropperResults, ImageCropperImperativeProps, ImageCropperProps } from '../ImageCropper';
import { ImageCropper } from '../ImageCropper';
import type { MenuItemType } from '../Menu';
import type { PopoverMenuProps } from '../PopoverMenu/PopoverMenu';
import { PopoverMenu } from '../PopoverMenu/PopoverMenu';
import { UI } from '../UI';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
// NOTE: do not remove the prettier-ignore below it is here so the ComponentPropsTable can strip out that line correctly for use with react-docgen
// prettier-ignore
import { UIColorContext,UIColors,UIShapes,UIShapesExtra,UISizeUnits,UISizes,UIVariants,type UIThemableComponent } from '../types/UI';
import { AcceptTypes, getMimeTypeFromFilename } from '../utils/file';
import { blobToBase64, dataURLtoFile, generateImageDataFromFile, selectImage, toDataURL } from '../utils/image';

export type ImagePickerResults = {
  type: string;
  size?: number;
  name?: string;
};

export type ImagePickerProps = UIThemableComponent & {
  ref?: React.Ref<HTMLDivElement>;

  value?: string;
  disabled?: boolean;
  popoverMenuProps?: PopoverMenuProps;
  onAccept?: (props: ImagePickerResults, file: File | undefined) => void;
  onChange?: (value?: File) => void;
  onCropChange?: (cropperResults: CropperResults) => void;
  onRemove?: (value: File) => void;

  // ----- Crop options

  width?: number;
  height?: number;
  units?: UISizeUnits;

  hasImageView?: boolean;
  hasDropzone?: boolean;
  hasCustomSize?: boolean;
  fixedCrop?: boolean;
  crop?: boolean;
  // crop?: boolean;
  cropperOptions?: ImageCropperProps;
};

export function ImagePicker({
  className,
  value,
  classNames,

  // ----- Crop options
  fixedCrop,
  crop,
  cropperOptions,

  width,
  height,
  units = UISizeUnits.Px,

  // ----- Themable props defaults
  color = UIColors.default,
  size = UISizes.md,
  variant = UIVariants.solid,
  shape = UIShapes.rounded,
  colorShadows,
  gradient,
  elevation,

  // ----- Options
  hasImageView, // TODO
  hasCustomSize,
  hasDropzone = true,
  disabled,
  popoverMenuProps,

  onChange,
  onCropChange,
  onRemove,
}: ImagePickerProps) {
  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, theme, gradient, elevation, disabled, colorShadows };

  const [_value, _setValue] = React.useState<string | undefined>();
  const [cropperResults, setCropperResults] = React.useState<CropperResults | undefined>();
  const [imagePreview, setImagePreview] = React.useState<string | undefined>();
  const [mimeType, setMimeType] = React.useState<string | false>();

  const imagePreviewRef = React.useRef<string>();
  imagePreviewRef.current = imagePreview;

  const [_image, setImage] = React.useState<File | string | undefined>();

  const fileRef = React.useRef<File>();

  const editorRef = React.useRef<ImageCropperImperativeProps | null>(null);

  const style = React.useMemo(() => {
    return { width: width + units, height: height + units };
  }, [height, width, units]);

  const setSrcAsFile = React.useCallback(async (value: string) => {
    if (!value) return;
    const dataUrl = await toDataURL(value);
    const dataString = dataUrl?.toString();
    if (!dataString) return;

    const file = dataURLtoFile(dataString, value);

    const mimeType = await getMimeTypeFromFilename(value);

    setMimeType(mimeType);

    fileRef.current = file;
    // onChange?.(file);
    const data = await generateImageDataFromFile(file);

    setImagePreview(data);
  }, []);

  const handleAcceptCrop = React.useCallback(async () => {
    const cropperResults = await editorRef.current?.getImage();

    if (!cropperResults) return;

    setCropperResults(cropperResults);

    setImagePreview(cropperResults.base64);
    imagePreviewRef.current = cropperResults.base64;

    // @ts-ignore
    const dataURLtoBlob = (await import('blueimp-canvas-to-blob')).default;
    const blob = dataURLtoBlob(cropperResults.base64);
    const file = new File([blob], fileRef.current?.name || 'noname');

    onChange?.(file);

    onCropChange?.(cropperResults);
  }, [onChange, onCropChange]);

  const cropImage = React.useCallback(
    async (file?: File) => {
      const _file = file || fileRef.current;
      if (!_file) return;
      const data = await generateImageDataFromFile(_file);
      if (!data) return;

      function getBorderRadius() {
        if (shape === UIShapes.rounded) return 8;
        if (shape === UIShapesExtra.circle) return 9999;

        return 0;
      }

      await UI.dialogs.dialog(
        ({ close }) => (
          <div>
            <ImageCropper
              ref={editorRef}
              hasZoom
              hasRotation
              hasCustomSize={hasCustomSize}
              image={data}
              zoom={cropperResults?.zoom}
              position={cropperResults?.position}
              rotate={cropperResults?.rotation}
              width={width}
              height={height}
              border={10}
              borderRadius={getBorderRadius()}
              {...cropperOptions}
              style={style}
              className={cn('mx-auto', { 'rounded-full': shape === UIShapesExtra.circle }, classNames?.cropper)}
            />
            <div className="text-center">
              <Button
                color={UIColors.primary}
                onClick={() => {
                  close();
                  handleAcceptCrop();
                }}
              >
                Accept
              </Button>
            </div>
          </div>
        ),
        {
          className: cn(classNames?.dialog),
        }
      );
    },
    [
      classNames?.dialog,
      classNames?.cropper,
      shape,
      hasCustomSize,
      cropperResults?.zoom,
      cropperResults?.position,
      cropperResults?.rotation,
      width,
      height,
      cropperOptions,
      style,
      handleAcceptCrop,
    ]
  );

  const selectFile = React.useCallback(async () => {
    const files = await selectImage();
    const file = files?.[0];
    if (!file) return;

    fileRef.current = file;

    const mimeType = await getMimeTypeFromFilename(file.name);

    setMimeType(mimeType);

    if (fixedCrop && mimeType !== AcceptTypes.SVG) {
      cropImage(file);
      return;
    }

    const data = await generateImageDataFromFile(file);

    setImagePreview(data);
    onChange?.(file);
  }, [fixedCrop, cropImage, onChange]);

  const removeFile = React.useCallback(() => {
    if (!fileRef.current) return;

    onChange?.();

    setImagePreview(undefined);
    // _setValue(undefined);
    fileRef.current = undefined;
    setCropperResults(undefined);
  }, [onChange]);

  const handleRemoveFile = React.useCallback(() => {
    if (!fileRef.current) return;

    onRemove?.(fileRef.current);
    removeFile();
  }, [onRemove, removeFile]);

  const handleDrop = React.useCallback(
    async (dropped: File[]) => {
      const file = dropped[0];
      if (!file) return;

      fileRef.current = file;
      setImage(file);
      onChange?.(file);

      const mimeType = await getMimeTypeFromFilename(file.name);

      setMimeType(mimeType);

      if (fixedCrop && mimeType !== AcceptTypes.SVG) {
        cropImage(file);
        return;
      }

      const base64 = await blobToBase64(file);
      if (base64) setImagePreview(base64);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fixedCrop, cropImage]
  );

  const viewFile = React.useCallback(() => {
    console.info('TODO: viewFile');
  }, []);

  const handleMenuSelect = React.useCallback(
    (item: MenuItemType) => {
      switch (item.id) {
        case 'view':
          viewFile();
          break;
        case 'remove':
          handleRemoveFile();
          break;
        case 'upload':
          selectFile();
          break;
        case 'update':
          cropImage();
          break;
      }
    },
    [viewFile, handleRemoveFile, selectFile, cropImage]
  );

  useEffectOnce(() => {
    if (value) setSrcAsFile(value);
  });

  const menuItems = React.useMemo(() => {
    const menuItems = [
      {
        text: 'Upload new image',
        icon: <UploadIcon />,
        id: 'upload',
      },
      {
        text: 'Remove image',
        icon: <TrashIcon />,
        id: 'remove',
      },
    ];

    if ((fixedCrop || crop) && mimeType !== AcceptTypes.SVG) {
      menuItems.unshift({
        text: 'Change crop',
        icon: <UploadIcon />,
        id: 'update',
      });
    }

    if (hasImageView) {
      menuItems.unshift({
        text: 'View image',
        icon: <EyeIcon />,
        id: 'view',
      });
    }

    return menuItems;
  }, [crop, fixedCrop, hasImageView, mimeType]);

  const finalImage = imagePreviewRef.current || imagePreview || _value;

  const comp = React.useMemo(() => {
    return (
      // NOTE the extra div with flex around the Box is here to avoid the browser adding extra spacing around the image element
      <div className="flex">
        <Box
          noPad
          {...themableProps}
          className={cn('ui-image-picker not-prose inline-flex h-24 w-24 items-center justify-center', className)}
          style={style}
        >
          {finalImage ? (
            <PopoverMenu
              useMousePosition
              {...themableProps}
              shape={UIShapes.rounded}
              onSelect={handleMenuSelect}
              closeOnSelect
              {...popoverMenuProps}
              items={menuItems}
              trigger={
                mimeType === AcceptTypes.SVG ? (
                  finalImage && (
                    <div
                      className={cn(
                        'image-box flex h-full w-full cursor-pointer justify-center p-6',
                        classNames?.imagebox
                      )}
                    >
                      <img src={`data:image/svg+xml;utf8,${encodeURIComponent(finalImage)}`} alt="Image preview" />
                    </div>
                  )
                ) : (
                  <div
                    className={cn(
                      'image-box flex h-full w-full cursor-pointer bg-contain bg-center bg-no-repeat',
                      UIStyling.shapes(themableProps)
                    )}
                    style={{
                      backgroundImage: `url('${finalImage}')`,
                    }}
                  />
                )
              }
              classNames={{ trigger: 'flex-1', wrapper: 'h-full w-full' }}
            />
          ) : (
            <div
              className={cn(
                'drop-box flex h-full w-full cursor-pointer items-center justify-center',
                classNames?.dropbox
              )}
              onClick={selectFile}
            >
              <PlusIcon className={cn('drop-add-icon', null, ['h-16 w-16'])} />
            </div>
          )}
        </Box>
      </div>
    );
  }, [
    className,
    classNames?.dropbox,
    classNames?.imagebox,
    finalImage,
    handleMenuSelect,
    menuItems,
    mimeType,
    popoverMenuProps,
    selectFile,
    style,
    themableProps,
  ]);

  if (hasDropzone) {
    return (
      <Dropzone onDrop={handleDrop} noClick noKeyboard>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {comp}
          </div>
        )}
      </Dropzone>
    );
  }

  return comp;
}

export const ImagePickerColors = Object.values(UIColorContext);
export const ImagePickerShapes = [...Object.values(UIShapes), UIShapesExtra.circle];
