'use client';

import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';

const CollapsibleBase = CollapsiblePrimitive.Root;

const CollapsibleTrigger = CollapsiblePrimitive.CollapsibleTrigger;

const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent;

export { CollapsibleBase, CollapsibleTrigger, CollapsibleContent };
