import { CodeBlock } from '@wolfejs/ui/CodeBlock/CodeBlock';
import type { UIComponentProps } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';
import type { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import React from 'react';

export type ApiErrorProps = UIComponentProps & {
  error: unknown | unknown[];
  withCodeBlock?: boolean;
};

export const ApiError = ({ error, className, withCodeBlock = false, ...otherProps }: ApiErrorProps) => {
  const parseMessage = React.useCallback((message: string | string[]) => {
    if (Array.isArray(message)) {
      return message.map((_message, index) => {
        return <div key={index}>{_message}</div>;
      });
    }

    return message;
  }, []);

  const parseError = React.useCallback(
    (error: unknown) => {
      if (error instanceof Error) {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_NETWORK') {
          return <div> Unable to connect to the remote server: {error.message}</div>;
        }

        const responseData = axiosError.response?.data as { message: string };

        if (axiosError.response?.status === StatusCodes.UNAUTHORIZED) {
          return (
            <div>
              ({axiosError.response?.status}) {axiosError.response?.statusText}: {responseData.message}
            </div>
          );
        } else if (axiosError.response?.status === StatusCodes.BAD_REQUEST) {
          return (
            <div>
              ({axiosError.response?.status}) {axiosError.response?.statusText}: {parseMessage(responseData.message)}
            </div>
          );
        }

        return (
          <div>
            <strong>
              {axiosError.code} {axiosError.name} :{' '}
            </strong>
            {axiosError.message}
            {withCodeBlock && <CodeBlock value={error} />}
          </div>
        );
      }

      return withCodeBlock ? <CodeBlock value={error} /> : <div>{error as string}</div>;
    },
    [parseMessage, withCodeBlock]
  );

  const ParsedError = React.useMemo(() => {
    return parseError(error);
  }, [error, parseError]);

  return (
    <div {...otherProps} className={cn('bg-error text-error-foreground rounded-lg p-2', className)}>
      {ParsedError}
    </div>
  );
};
