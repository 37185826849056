import React from 'react';
import { cn } from '../utils/classnames';
import type { InputNumberProps } from './InputNumber';
import { InputNumber } from './InputNumber';

export type CurrencySignsType = {
  [key: string]: string;
};

export type SupportedCurrencies = 'USD' | 'EUR' | 'THB';

// TODO: this should be moved somewhere else and more currencies should be added
// or this can come from a database
export const currencySigns: CurrencySignsType = {
  USD: '$',
  EUR: '€',
  THB: '฿',
};

export type InputCurrencyProps = InputNumberProps & {
  ref?: React.RefObject<HTMLInputElement> | React.RefCallback<HTMLInputElement>;

  /**
   * Currency code
   */
  code?: SupportedCurrencies;

  /**
   * Use the currency's sign as a prefix or suffix (use "none" to disable)
   */
  sign?: 'prefix' | 'suffix' | 'none' | string;

  /**
   * Separator to add between the sign and the value
   */
  signSeparator?: string;
};

// block name for this component
const blockName = `input-currency`;

/**
 * GIK Input component for currencies
 */
function InputCurrencyComp(
  {
    code = 'USD',
    signSeparator = ' ',
    sign = 'prefix',
    className,
    formatProps = {},
    ...otherProps
  }: InputCurrencyProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>
): React.ReactElement {
  const _sign: string = currencySigns[code];

  if (sign === 'prefix') {
    formatProps.prefix = _sign + signSeparator;
  } else if (sign === 'suffix') {
    formatProps.suffix = signSeparator + _sign;
  }

  return (
    <InputNumber // @ts-ignore : using ref here works but seems undefined in the react-number-format-typing
      ref={forwardedRef}
      className={cn(['input', blockName, className || ''])}
      {...otherProps}
      formatProps={formatProps}
    />
  );
}

export const InputCurrency = React.forwardRef<HTMLDivElement, InputCurrencyProps>(
  InputCurrencyComp
) as React.ForwardRefExoticComponentExtended<InputCurrencyProps>;
