import z from 'zod';

export const FormSchemaEntrySchema = z.object({
  email: z.string().email(),
  password: z.string().min(4),
  firstName: z.string().min(4),
  lastName: z.string().min(4),
  dob: z.date(),
  image: z.string(),
  street: z.string(),
  street2: z.string(),
  city: z.string(),
  zipCode: z.number().min(5),
  state: z.string(),
  country: z.string(),
  name: z.string().min(4),
  displayName: z.string().min(4),
  description: z.string().min(4),
  logo: z.string().min(4),
  caption: z.string().min(4),
  phoneNumber: z.string().min(10),
  contactName: z.string().min(4),
  contactEmail: z.string().email(),
  contactPhoneNumber: z.string().min(10),
});
