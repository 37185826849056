'use client';

import React from 'react';
import type {
  ActionMeta,
  ClassNamesConfig,
  GroupBase,
  MultiValue,
  OptionProps,
  SingleValue,
  SingleValueProps,
  StylesConfig,
} from 'react-select';
import { components } from 'react-select';
import type { AsyncProps } from 'react-select/async';
import ReactSelectAsync from 'react-select/async';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
import type { UIThemableComponent } from '../types/UI';
import { UIColorContext, UIShapes, UISizes, UIVariants } from '../types/UI';
import { cn } from '../utils/classnames';
import type { SelectOption } from './Select';

export type SelectRemoteValueType = string | number | readonly string[] | undefined;

export type SelectRemoteOption<T = SelectRemoteValueType> = {
  label: string;
  value: T | undefined;
  disabled?: boolean;

  autoComplete?: string;
};

export type SelectRemoteProps<
  Option extends SelectOption = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = UIThemableComponent &
  AsyncProps<Option, IsMulti, Group> & {
    value?: Option | null;
    initialValue?: Option;
    open?: boolean;
    autoWidth?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    dropdownWidth?: string;
    showIconInSelection?: boolean;
    prepend?: React.ReactNode;
    fixedPosition?: boolean;
    // fetchFn?: (searchParams?: SearchParams) => unknown;
    onChange?: (value: Option | null, reason: ActionMeta<Option>) => void;
  };

const Option = components.Option;
const SingleValueComp = components.SingleValue;

export function SelectRemote<
  Option extends SelectOption = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  cname,
  className,

  // ----- Options
  autoWidth,
  fullWidth,
  fixedPosition,
  disabled,
  showIconInSelection,
  dropdownWidth,

  // ----- Select props

  open,

  value,
  defaultValue,

  isDisabled,

  // ----- Themable props defaults
  color = UIColorContext.default,
  variant = UIVariants.solid,
  shape = UIShapes.rounded,
  size = UISizes.md,
  gradient,
  elevation,
  classNames,
  prepend,

  onChange,
  ...otherProps
}: SelectRemoteProps<Option, IsMulti, Group>) {
  const [isOpen, setIsOpen] = React.useState<boolean>();

  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, gradient, elevation, theme };

  const handleChange = React.useCallback(
    (value: MultiValue<Option> | SingleValue<Option> | null, reason: ActionMeta<Option>) => {
      if (reason?.action === 'clear') {
        return onChange?.(null, reason);
      }

      onChange?.(value as SingleValue<Option>, reason);
    },
    [onChange]
  );

  const _classNames: ClassNamesConfig<Option, IsMulti, Group> =
    variant === UIVariants.unstyled
      ? {}
      : {
          container: () =>
            cn(
              { ['w-full']: fullWidth },
              { [`bg-default-50 dark:bg-default-700`]: variant === UIVariants.solid },
              { [`bg-none`]: variant === UIVariants.outline }
            ),
          menuList: () => cn({ ['bg-white dark:bg-default-800']: variant === UIVariants.outline }),
          menu: () =>
            cn(
              'min-w-full',
              {
                'bg-white dark:bg-default-800 left-[-1px] box-content border border-default-300 dark:border-default-600 border-t-0':
                  theme === 'flowbite',
              },
              {
                [`border-${color}-300 dark:${color}-600`]: theme === 'flowbite' && variant === UIVariants.outline,
              },
              classNames?.menu
            ),
          menuPortal: () => 'z-select',
          placeholder: () => cn({ [`text-default-900 dark:text-white`]: theme === 'flowbite' }),
          option: state =>
            cn(
              `flex items-center px-2 py-2`,
              {
                // Background, text colors, hover state
                [`bg-default hover:bg-default-hover`]: variant === UIVariants.solid && theme !== 'flowbite',
                [`text-default-900 dark:text-white`]: variant === UIVariants.solid && theme === 'flowbite',
                [`hover:bg-${color}-hover hover:text-white`]:
                  theme === 'flowbite' && (variant === UIVariants.solid || variant === UIVariants.outline),
                [`text-default-foreground dark:text-default-300`]:
                  theme === 'flowbite' && state.isSelected && variant === UIVariants.outline,
              },
              {
                // Selected option state
                [`bg-default-active`]: state.isSelected && theme !== 'flowbite',
                [`bg-${color}-active dark:bg-${color}-active text-white`]: state.isSelected && theme === 'flowbite',
                [`text-white`]: theme === 'flowbite' && state.isSelected && variant === UIVariants.outline,
              }
            ),
          control: () =>
            cn('bg-transparent', { 'ml-2 mr-1': theme !== 'flowbite' }, { 'min-h-fit': theme === 'flowbite' }),
          valueContainer: () => cn('cursor-pointer', { 'p-2.5': theme === 'flowbite' }),
          singleValue: () => cn('flex items-center', { [`text-default-900 dark:text-white`]: theme === 'flowbite' }),
          indicatorsContainer: () =>
            cn({
              [`text-default-500 px-2.5`]: theme === 'flowbite',
            }),
        };

  const styles: StylesConfig<Option, IsMulti, Group> = {
    menu: (base: object) => ({
      ...base,
      width: dropdownWidth || (autoWidth ? 'max-content' : '100%'),
    }),
    option: (base: object) => ({ ...base, cursor: 'pointer' }),
  };

  const selectProps: AsyncProps<Option, IsMulti, Group> = {
    menuIsOpen: open,
    classNamePrefix: cname,
    components: {
      SingleValue: (props: SingleValueProps<Option, IsMulti, Group>) => {
        const { children, data, ...otherProps } = props;

        return (
          <SingleValueComp data={data} {...otherProps}>
            {showIconInSelection && <div className="mr-2 h-6 w-6">{data.icon}</div>}
            <span className="mt-[1px] flex items-center gap-2">
              {prepend}
              {children}
            </span>
          </SingleValueComp>
        );
      },
      Option: (props: OptionProps<Option, IsMulti, Group>) => {
        const { children, data, ...otherProps } = props;

        return (
          <Option data={data} {...otherProps}>
            {data.icon && <div className="mr-2 h-6 w-6">{data.icon}</div>}
            <span className="mt-[1px] flex items-center gap-2">
              {prepend}
              {children}
            </span>
          </Option>
        );
      },
    },
    className:
      variant === UIVariants.unstyled
        ? cname
        : cn(
            cname,
            'inline-block',
            UIStyling.shapes(themableProps),
            UIStyling.textSizes(themableProps),
            UIStyling.elevations(themableProps),
            UIStyling.focusVisibleRing(themableProps),
            { disabled },
            { ['border-none']: variant !== UIVariants.outline && theme !== 'flowbite' },
            { 'rounded-b-none': open || isOpen }, // apply open theming if open is true but the container is not focused
            {
              [`border border-default-300 dark:border-default-600 dark:placeholder-default-400`]:
                theme === 'flowbite' && (variant === UIVariants.outline || variant === UIVariants.solid),
              // Outline Borders
              [`border-${color}-300 dark:border-${color}-600`]: theme === 'flowbite' && variant === UIVariants.outline,
            },
            className
          ),

    unstyled: true,
    isClearable: true,
    blurInputOnSelect: true,
    isDisabled,
    menuPortalTarget: fixedPosition ? document.body : undefined,
    ...otherProps,
    classNames: _classNames,
    defaultOptions: true,
    styles,

    onChange: handleChange,

    // value={_value}
    // menuIsOpen={open}
    // isClearable
    // blurInputOnSelect
    // classNamePrefix="react-select"
    // isDisabled={isDisabled}
    // unstyled={true}
    // classNames={_classNames}
    // {...otherProps}
    // onChange={onChange}
  };

  return (
    // @ts-ignore
    <ReactSelectAsync
      // value={_value}
      // menuIsOpen={open}
      // isClearable
      // blurInputOnSelect
      // classNamePrefix="react-select"
      // isDisabled={isDisabled}
      // unstyled={true}
      // classNames={_classNames}
      // {...otherProps}
      // onChange={onChange}
      {...selectProps}
    />
  );
}
