'use client';

import React from 'react';
import type { FieldValues } from 'react-hook-form';
import { Form } from '.';
import type { ButtonProps } from '../Button';
import { Button } from '../Button';
import type { UIThemableComponent } from '../types/UI';
import { cn } from '../utils/classnames';
import type { FormRef, MultiFormSchemaEntry } from './types';

export type MultiFormProps = UIThemableComponent & {
  schema: MultiFormSchemaEntry[];
  submitButtonText?: string;
  submitButtonProps?: Omit<ButtonProps, 'onClick'>;
  onSubmit?: (data: Record<string, FieldValues>) => void;
};

const mapDefaultValues = (schema: MultiFormSchemaEntry[]) => {
  return schema.reduce((acc, entry) => {
    return {
      ...acc,
      [entry.formId]: entry.formProps?.defaultValues ?? {},
    };
  }, {});
};

/**
 * Dynamically creates & manages a formRef for each MultiFormSchemaEntry passed.
 * Validates all forms onSubmit using react-hook-form.
 * Exposes an onSubmit callback invoked when all forms are valid.
 */
export function MultiForm({ className, schema, submitButtonText, submitButtonProps, onSubmit }: MultiFormProps) {
  const formRefs = React.useRef(schema.map(() => React.createRef<FormRef>())).current;
  const [formsData, setFormsData] = React.useState<Record<string, FieldValues>>(mapDefaultValues(schema));

  const handleFormChange = (formId: string, data: FieldValues) => {
    setFormsData(prevData => ({
      ...prevData,
      [formId]: data,
    }));
  };

  const handleSubmitAll = () => {
    // "Submit" all react-hook-forms to validate
    formRefs.forEach(formRef => {
      if (formRef.current?.handleSubmit) {
        formRef.current.handleSubmit();
      }
    });

    // Only allow submission of forms if all are valid
    const allFormsAreValid = formRefs.every(formRef => formRef.current?.isValid);
    if (allFormsAreValid) {
      onSubmit?.(formsData);
    }
  };

  return (
    <div className={cn('@container', className)}>
      {schema.map(({ formId, formSchema, formProps }, index) => (
        <Form
          key={formId}
          formRef={formRefs[index]}
          schema={formSchema}
          onChange={(data, _name, _type) => handleFormChange(formId, data)}
          {...formProps}
        />
      ))}
      <Button {...submitButtonProps} onClick={handleSubmitAll}>
        {submitButtonText ? submitButtonText : 'Submit All'}
      </Button>
    </div>
  );
}
