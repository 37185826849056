'use client';

import React from 'react';
import type { MenuItemRecursive } from '../Menu';
import type { MenuProps } from '../Menu/Menu';
import { Menu } from '../Menu/Menu';
import { Popover, type PopoverProps } from '../Popover';
import { useTheme } from '../hooks/useTheme';
import { UIColors, UIShapes, UISizes, UIVariants } from '../types/UI';
import { cn } from '../utils/classnames';
import { renderSlot } from '../utils/slots';

export type PopoverMenuProps = PopoverProps &
  MenuProps & {
    append?: React.ReactNode;
    prepend?: React.ReactNode;
    closeOnSelect?: boolean;
    onSelect?: (item: MenuItemRecursive) => void;
    onOpen?: () => void;
    onClose?: () => void;
  };

/**
 * PopoverMenu
 *
 * See the Popover and the Menu components for more details.
 *
 * @description A Popover with a Menu inside it.
 * @version 1.0.0
 * @author Aaike Van Roekeghem - aaike.vanroekeghem@wolfe.com
 * @example
 * import type { PopoverMenu } from '@wolfejs/ui/PopoverMenu';
 * import type { MenuItemType } from '@wolfejs/ui/Menu';
 * const menuItems: MenuItemType[] = [{ text: 'Item 1', icon: <PlusIcon /> }, { text: 'Item 2', icon: <PlusIcon /> }];
 * <PopoverMenu items={menuItems} trigger={<Button>Click me</Button>} />
 * @example
 * const handleSelect = React.useCallback((item: MenuItemType) => { ... }, [])
 * <PopoverMenu items={menuItems} trigger={<Button>Click me</Button>} onSelect={handleSelect} />
 */
export const PopoverMenu = ({
  cname = 'ui-popover-menu',
  className,
  classNames,
  slots,
  slotProps,

  // ----- Themable props defaults
  // Note: all themable props are passed down to the menu component
  color = UIColors.default,
  size = UISizes.md,
  variant = UIVariants.solid,
  shape = UIShapes.rounded,
  colorShadows,
  gradient,
  elevation,

  // ----- MenuProps
  disabled,
  depth,
  layout,
  items,
  renderItem,

  // ----- Options
  closeOnSelect,
  append,
  prepend,
  onOpen,
  onClose,
  onSelect,
  ...otherProps
}: PopoverMenuProps) => {
  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, theme, gradient, elevation, colorShadows };
  const menuProps = { disabled, depth, items, layout, renderItem };

  const [open, setOpen] = React.useState(false);

  const handleSelect = React.useCallback(
    (item: MenuItemRecursive) => {
      if (closeOnSelect) setOpen(false);
      onSelect?.(item);
    },
    [closeOnSelect, onSelect]
  );

  const handleOpen = React.useCallback(() => {
    setOpen(true);
    onOpen?.();
  }, [onOpen]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose]);

  return (
    <Popover
      // defaults
      useClick
      {...otherProps}
      // non overridable props
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      className={cn(cname, className)}
      classNames={{ trigger: 'w-full h-full', wrapper: 'h-full w-full', ...classNames }}
    >
      {slots?.prepend &&
        renderSlot(
          slots.prepend,
          `${cname}__prepend`,
          { ...slotProps?.prepend, children: prepend },
          classNames?.prepend
        )}

      <Menu
        {...themableProps}
        {...menuProps}
        className={classNames?.menu}
        classNames={classNames}
        onClick={handleSelect}
      />

      {slots?.append &&
        renderSlot(slots.append, `${cname}__append`, { ...slotProps?.append, children: append }, classNames?.append)}
    </Popover>
  );
};
