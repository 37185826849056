'use client';

import React from 'react';
import type { NumberFormatValues, PatternFormatProps } from 'react-number-format';
import { PatternFormat } from 'react-number-format';
import { Input, type InputProps } from './Input';

export type InputPhoneProps = InputProps & {
  ref?: React.ForwardedRef<HTMLInputElement>;

  formatProps?: PatternFormatProps;
  // eslint-disable-next-line
  onFormattedValueChange?(value: NumberFormatValues): void;
};

const InputPhoneComp = (
  {
    onValueChange,
    value,
    onFormattedValueChange,
    formatProps = { format: '###-###-####', mask: '#' },
    ...otherProps
  }: InputPhoneProps,
  forwardedRef: React.ForwardedRef<HTMLInputElement>
) => {
  // also pass the placeholder and disabled props to the PatternFormat component
  formatProps.placeholder = otherProps.placeholder;
  formatProps.disabled = otherProps.disabled;

  const handleValueChange = React.useCallback(
    (values: NumberFormatValues) => {
      onFormattedValueChange?.(values);
      onValueChange?.(values.value);
    },
    [onValueChange, onFormattedValueChange]
  );

  return (
    <Input
      {...otherProps}
      ref={forwardedRef}
      customInput={() => {
        return (
          <PatternFormat
            className="w-full border-none bg-transparent p-0 outline-none"
            onValueChange={handleValueChange}
            // thousandsGroupStyle="lakh"
            type="text"
            displayType="input"
            value={value}
            // @ts-ignore : using ref here works but seems undefined in the react-number-format-typing
            {...formatProps}
          />
        );
      }}
    />
  );
};

export const InputPhone = React.forwardRef<HTMLInputElement, InputPhoneProps>(
  InputPhoneComp
) as React.ForwardRefExoticComponentExtended<InputPhoneProps>;
