import type { ImageFormat } from 'exif-rotate-js';
import type { AcceptType, SelectFileProps, SelectFileResponse } from './file';
import { AcceptTypes, getMimeTypeFromFilename, selectFile } from './file';

export type SVGImage = string | ArrayBuffer | null;

export type RasterImageMeta = {
  blob: Blob;
  base64: string;
  width: number;
  height: number;
  type: ImageFormat;
};

export type GifImageMeta = {
  objectUrl: string;
};

export type SVGImageMeta = {
  vector: SVGImage;
};

export type SelectImageProps = SelectFileProps;
export type SelectImageResponse<M = unknown> = SelectFileResponse<M>;

export const DefaultMaxImageAttachmentSize = 1920;

export const imageNotFoundGradient = 'linear-gradient(45deg,#2fc3e4,#41cbce 19.47%,#41cbce 78.03%,#00b7b0)';

const imageAcceptTypes: AcceptType[] = [
  AcceptTypes.JPEG,
  AcceptTypes.PNG,
  AcceptTypes.APNG,
  AcceptTypes.AVIF,
  AcceptTypes.GIF,
  AcceptTypes.SVG,
  AcceptTypes.WEBP,
];

export function imageOrNotFoundGradient(imageUrl: string): string {
  return imageUrl ? `url("${imageUrl}")` : imageNotFoundGradient;
}

/**
 * Create and `Image` object from a `src` path that points to an image.
 */
export const createImage = (src: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', () => reject(null));
    image.src = src;
  });

/**
 * Open a browser file selection window to allow the user to pick images.
 *
 * Accepted types are 'jpg', 'png', 'gif' and 'svg'
 */
export async function selectImage(props?: SelectImageProps): Promise<FileList | undefined> {
  return new Promise((resolve, reject) => {
    (async () => {
      const files = await selectFile({
        ...props,
        acceptTypes: props?.acceptTypes ?? imageAcceptTypes,
      });

      if (!files) return reject(new Error('unable to get select browser file'));

      resolve(files);
    })();
  });
}

/**
 * Takes a File and converts it into an image that can be used by the browser.
 *
 * Accepts GIF files that will be read using `URL.createObjectURL`
 * Accepts SVG files that will be read using `FileReader`
 * Accepts JPG/PNG files that will read using `getBase64Strings` from the `exif-rotate-js` package
 */
export async function generateImageDataFromFile(
  file: File,
  convertToType: ImageFormat = 'image/jpeg'
): Promise<string | undefined> {
  return new Promise(resolve => {
    (async () => {
      const mimeType = await getMimeTypeFromFilename(file.name);

      if (mimeType === 'image/gif') {
        // const meta: GifImageMeta = { objectUrl: URL.createObjectURL(file) };
        return resolve(URL.createObjectURL(file));
      }

      if (mimeType === 'image/svg+xml') {
        const reader = new FileReader();

        reader.onload = function (e) {
          const vector = e.target ? e.target.result : null;
          // const meta: SVGImageMeta = { vector };
          resolve((vector as string) || undefined);
        };

        reader.readAsText(file);
        return;
      }

      //----- assume jpg or raster type image by default

      const { getBase64Strings } = await import('exif-rotate-js');

      const base64 = (
        await getBase64Strings([file], {
          maxSize: 1920,
          type: convertToType,
        })
      )[0];

      resolve(base64);
    })();
  });
}

export const blobToBase64 = (blob: Blob): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result?.toString());
    reader.onerror = error => reject(error);
  });
};

export const toDataURL = (url: string): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    (async () => {
      let response;
      try {
        response = await fetch(url);
      } catch (err) {
        console.error('Image Fetch Error', err);
      }

      if (!response) return;

      const blob = await response.blob();

      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    })();
  });
};

export const getSrcAsBlob = async (src: string) => {
  const response = await fetch(src);

  if (!response) return;

  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');

  const part = arr?.[0],
    mime = part?.match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]);

  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
