// eslint-disable-next-line
export function removeEmptyValues(obj: any): any {
  //if object is an File
  if (obj && obj.constructor.name == 'File') return obj;

  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null && v != undefined && !isEmptyObject(obj))
      .map(([k, v]) => [k, v === Object(v) && !(v instanceof Date) ? removeEmptyValues(v) : v])
  );
}

// eslint-disable-next-line
export function isEmptyObject(obj: any): boolean {
  if (typeof obj !== 'object') return false;

  return Object.keys(obj).length === 0;
}
