'use client';

import type { SwitchProps as OriginalSwitchProps } from '@radix-ui/react-switch';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import * as React from 'react';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
import type { UIThemableComponent } from '../types/UI';
import { UIColors, UIShapesExtra, UISizes, UIVariants } from '../types/UI';
import { cn } from '../utils/classnames';

export type SwitchElement = HTMLButtonElement;
export type SwitchProps = OriginalSwitchProps &
  UIThemableComponent & {
    showRequiredIndicator?: boolean;
  };

const SwitchComp = (
  {
    className,
    // ----- Themable props defaults
    color = UIColors.default,
    size = UISizes.sm,
    variant = UIVariants.solid,
    shape = UIShapesExtra.pill,
    gradient,
    colorShadows,
    elevation,
    ...props
  }: SwitchProps,
  ref: React.ForwardedRef<SwitchElement>
) => {
  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, theme, gradient, elevation };

  return (
    <SwitchPrimitives.Root
      className={cn(
        'peer inline-flex shrink-0 cursor-pointer items-center transition-colors',
        UIStyling.variants(themableProps),
        UIStyling.shapes(themableProps),
        UIStyling.elevations(themableProps),
        UIStyling.disabled(themableProps),
        UIStyling.focusVisibleRing(themableProps),
        colorShadows && UIStyling.colorShadows(themableProps),
        // Sizes
        {
          ['h-4 w-8']: size === UISizes.xs,
          ['h-6 w-11']: size == UISizes.sm,
          ['h-8 w-14']: size == UISizes.md,
          ['h-10 w-16']: size == UISizes.lg || size == UISizes.xl,
        },
        {
          [`data-[state=unchecked]:bg-default-200 data-[state=checked]:bg-${color}-600`]:
            variant === UIVariants.solid && theme === 'flowbite',
        },
        className
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          'pointer-events-none block transition-transform data-[state=unchecked]:translate-x-0',
          UIStyling.shapes(themableProps),
          {
            [`bg-${color}`]: variant === UIVariants.outline,
            [`bg-white`]: variant === UIVariants.solid,
          },
          {
            ['h-3 w-3 data-[state=checked]:translate-x-2']: size === UISizes.xs,
            ['h-5  w-5 data-[state=checked]:translate-x-5']: size == UISizes.sm,
            ['h-6  w-6 data-[state=checked]:translate-x-6']: size == UISizes.md,
            ['h-7  w-7 data-[state=checked]:translate-x-7']: size == UISizes.lg || size == UISizes.xl,
          }
        )}
      />
    </SwitchPrimitives.Root>
  );
};

export const Switch = React.forwardRef<SwitchElement, SwitchProps>(
  SwitchComp
) as React.ForwardRefExoticComponentExtended<SwitchProps>;

export const SwitchVariants = [UIVariants.solid, UIVariants.outline, UIVariants.unstyled];
