import type { ImageFormat } from 'exif-rotate-js';

// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
export enum AcceptTypes {
  WEBP = 'image/webp',
  GIF = 'image/gif',
  PNG = 'image/png',
  APNG = 'image/apng',
  AVIF = 'image/avif',
  JPEG = 'image/jpeg',
  SVG = 'image/svg+xml',
  TEXT = 'text/text',
  CSV = 'text/csv',
  VCF = 'text/vcf',
}

export type AcceptType = AcceptTypes | string;

export type SelectFileResponse<M = unknown> = {
  files: FileList;
  meta?: M;
};

export type SelectFileProps = {
  acceptTypes?: AcceptType[];
  multiple?: boolean;
  convertToType?: ImageFormat;
};

/**
 * Open a browser file selection window to allow the user to pick files
 */
export async function selectFile(props?: SelectFileProps): Promise<FileList | undefined> {
  const { acceptTypes = [], multiple = false } = props || {};

  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = acceptTypes.join(',');
  fileInput.hidden = true;
  fileInput.multiple = multiple;
  document.body.appendChild(fileInput);

  return new Promise((resolve, reject) => {
    fileInput.addEventListener('change', async fileChangeEvent => {
      const target = fileChangeEvent.target as HTMLInputElement;
      if (!fileChangeEvent.target) {
        return reject(new Error('unable to find input element'));
      }

      try {
        const files = target.files;
        if (!files) return reject(new Error('unable to select file'));

        resolve(files);
      } catch (err) {
        return reject(new Error('unresolved upload error'));
      }
    });

    fileInput.click();
  });
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

/**
 * Determines the mime type based on file extension using the `mime-types` package.
 */
export async function getMimeTypeFromFilename(filename: string) {
  const mime = (await import('mime-types')).default;
  return mime.lookup(filename);
}
