'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { removeEmptyValues } from '@wolfejs/core/utils/object';
import { ApiError } from '@wolfejs/ms-api-react/src/components/ApiError';
import React from 'react';
import type {
  DeepPartial,
  EventType,
  FieldValues,
  Path,
  SubmitHandler,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import type { ButtonProps } from '../Button';
import { Button } from '../Button';
import { Divider } from '../Divider';
import { Title } from '../Title';
import type { UIThemableComponent } from '../types/UI';
import { type HorVerLayout } from '../types/UI';
import { cn } from '../utils/classnames';
import type { FormContextProps } from './FormContext';
import { FormContext } from './FormContext';
import { FormField } from './FormField';
import type { FormFieldsetItem, FormRef, FormSchemaEntry } from './types';

export * from './FormField';
export * from './FormGroup';

function getDefaultZodValidationForInputType(entry: FormSchemaEntry) {
  switch (entry.type) {
    case 'switch':
    case 'checkbox':
      return z.literal<boolean>(true);
    case 'slider':
      return z.array(z.number());
    case 'select':
      return z.object({ label: z.string(), value: z.string() });
    default:
      return z.string();
  }
}

export type FormProps<
  Values extends FieldValues,
  ValidationSchema extends Zod.ZodType | undefined = undefined,
> = UseFormProps<Values> &
  UIThemableComponent & {
    formRef?: React.MutableRefObject<FormRef | null>;
    schema: FormSchemaEntry[];
    validationSchema?: ValidationSchema;
    formStructure?: FormFieldsetItem[];
    render?: (formProps: UseFormReturn<FieldValues>) => React.ReactNode;
    onSubmit?: (data: Values) => Promise<void> | void;
    onChange?: (
      data: DeepPartial<Values>,
      name: Path<Values> | undefined,
      type: EventType | undefined
    ) => Promise<void> | void;
    hasSubmitButton?: boolean;
    submitButtonText?: string;
    submitButtonProps?: ButtonProps;
    actions?: React.ReactNode;
    error?: Error | string | null;
    layout?: HorVerLayout;
    fieldsetLayoutClasses?: string;
    title?: string;
    prepend?: React.ReactNode;
    append?: React.ReactNode;
    noDividers?: boolean;
    isLoading?: boolean;
  };

export function Form<Values extends FieldValues, ValidationSchema extends Zod.ZodType | undefined = undefined>({
  formRef,
  schema,
  validationSchema,
  formStructure,
  actions,
  error,
  className,
  classNames,
  render,
  hasSubmitButton,
  submitButtonText,
  fieldsetLayoutClasses,
  submitButtonProps,
  title,
  onSubmit,
  onChange,
  prepend,
  append,
  noDividers,
  isLoading,
  ...otherProps
}: FormProps<Values, ValidationSchema>) {
  const zodSchema = React.useMemo(() => {
    const output: Record<string, z.ZodType> = {};
    // const requiredFields: { [x: string]: true | undefined } = {};
    // generate a zodschema from the form schema
    schema.forEach(entry => {
      output[entry.name] = entry.validation ?? getDefaultZodValidationForInputType(entry);
      // if (entry.required) requiredFields[entry.name] = true;
    });

    const zodObject = z.object(output);
    // zodObject.required(requiredFields);
    return zodObject;
  }, [schema]);

  const formProps = useForm<Values>({
    resolver: zodResolver(validationSchema || zodSchema),
    ...otherProps,
  });

  const { handleSubmit, register, formState, watch, control } = formProps;

  const _onSubmit: SubmitHandler<Values> = data => {
    return onSubmit?.(removeEmptyValues(data));
  };

  if (formRef?.current === null) {
    formRef.current = {
      handleSubmit: handleSubmit(_onSubmit ? _data => _onSubmit(_data) : _data => {}),
      isValid: false,
    };
  }
  if (formRef?.current) {
    formRef.current.isValid = formState.isValid;
  }

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      onChange?.(removeEmptyValues(value), name, type);
    });
    return () => subscription.unsubscribe();
  }, [onChange, watch, formState]);

  const defaultContext: FormContextProps<Values> = { schema, register, formState, control };

  return (
    // @ts-ignore
    <FormContext.Provider value={defaultContext}>
      <form onSubmit={handleSubmit(_onSubmit)} noValidate className={cn(className)}>
        {title && <Title as="h3">{title}</Title>}
        {prepend && <div>{prepend}</div>}
        {formStructure?.length ? (
          formStructure.map(structure => {
            return (
              <fieldset
                key={structure.name}
                className={cn({
                  ['flex flex-col gap-2']: !fieldsetLayoutClasses,
                  [`${fieldsetLayoutClasses}`]: fieldsetLayoutClasses,
                })}
              >
                {structure?.label && (
                  <div className="flex justify-between">
                    <Title as="h4">{structure?.label}</Title>
                    <Button variant="link" color="primary">
                      Select all
                    </Button>
                  </div>
                )}
                {!render &&
                  schema
                    .filter(entry => entry.fieldsetName === structure.name)
                    .map((entry: FormSchemaEntry) => {
                      return <FormField key={entry.name} name={entry.name} />;
                    })}
              </fieldset>
            );
          })
        ) : (
          <fieldset
            className={cn({
              ['flex flex-col gap-2']: !fieldsetLayoutClasses,
              [`${fieldsetLayoutClasses}`]: fieldsetLayoutClasses,
            })}
          >
            {!render &&
              schema.map((entry: FormSchemaEntry) => {
                return <FormField key={entry.name} name={entry.name} />;
              })}

            {render && (
              <>
                {// @ts-ignore
                render?.(formProps)}
                {error && <ApiError error={error} />}
              </>
            )}
          </fieldset>
        )}

        {append && <div>{append}</div>}
        {!noDividers && <Divider />}
        {(actions || (!render && hasSubmitButton)) && (
          <div className={cn('mb-4', classNames?.actions)}>
            {actions || (
              <Button
                type="submit"
                color="primary"
                {...submitButtonProps}
                // TODO: fix this -> the formState is never valid
                // disabled={!formState.isValid || submitButtonProps?.disabled}
                loading={formState.isSubmitting || isLoading}
              >
                {submitButtonText ? submitButtonText : 'Submit'}
              </Button>
            )}
            {error && <ApiError className="mt-4" error={error} />}
          </div>
        )}
      </form>
    </FormContext.Provider>
  );
}
