'use client';

import { CollapsibleContent, CollapsibleTrigger } from '@radix-ui/react-collapsible';
import React from 'react';
import { ChevronDown, ChevronUp } from '@wolfejs/icons/IconMap';
import type { HorVerLayout, UIComponentProps, UIThemableComponent } from '@wolfejs/ui/types/UI';
import { LayoutTypes, UIColors, UIShapes, UISizes, UIVariants } from '@wolfejs/ui/types/UI';
import { Box } from '../Box';
import { CollapsibleBase } from '../Collapsible/CollapsibleBase';
import { useTheme } from '../hooks/useTheme';
import { cn } from '../utils/classnames';
import { MenuItem } from './MenuItem';
import type { MenuItemRecursive } from './types';

export type MenuProps<MenuItemType extends MenuItemRecursive = MenuItemRecursive> = UIThemableComponent<
  UIComponentProps<HTMLUListElement>,
  HTMLUListElement
> & {
  disabled?: boolean;
  depth?: number;
  items: MenuItemType[];
  layout?: HorVerLayout;
  renderItem?: (item: MenuItemRecursive) => React.ReactNode;
  onClick?: (item: MenuItemRecursive) => void;
};

function MenuComp<MenuItemType extends MenuItemRecursive = MenuItemRecursive>(
  {
    cname = 'ui-menu',
    className,
    classNames,

    items,
    layout = LayoutTypes.vertical,
    onClick,

    // ----- Themable props defaults
    color = UIColors.default,
    size = UISizes.md,
    variant = UIVariants.solid,
    shape = UIShapes.rounded,
    colorShadows,
    gradient,
    elevation,

    // ----- Options
    disabled,
    depth = 0,

    renderItem,
    ...otherProps
  }: MenuProps<MenuItemType>,
  forwardedRef: React.ForwardedRef<HTMLUListElement>
) {
  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, theme, gradient, elevation, disabled, colorShadows, layout };
  const [open, setOpen] = React.useState<boolean>(false);

  const menuProps: Partial<Omit<MenuProps, 'ref'>> & { ref?: React.ForwardedRef<HTMLUListElement> } = {
    ...otherProps,
  };

  if (depth === 0) menuProps.ref = forwardedRef;

  const itemClasses = React.useMemo(() => {
    if (variant === UIVariants.unstyled) return cn(classNames?.item);

    return cn(
      'cursor-pointer',
      {
        ['[&:first-of-type>div]:rounded-t-md']: shape === UIShapes.rounded && layout === LayoutTypes.vertical,
        ['[&:last-of-type>div]:rounded-b-md']: shape === UIShapes.rounded && layout === LayoutTypes.vertical,
        ['[&:first-of-type>div]:rounded-l-md']: shape === UIShapes.rounded && layout === LayoutTypes.horizontal,
        ['[&:last-of-type>div]:rounded-r-md']: shape === UIShapes.rounded && layout === LayoutTypes.horizontal,
      },
      classNames?.item
    );
  }, [classNames?.item, layout, shape, variant]);

  const menu = (
    // @ts-ignore
    <ul
      {...menuProps}
      // ref={forwardedRef ? forwardedRef : undefined}
      className={cn(
        cname,
        'flex flex-1',
        {
          ['flex-col']: layout === LayoutTypes.vertical,
          ['flex']: layout === LayoutTypes.horizontal,
        },
        className
      )}
    >
      {items?.map(item => {
        return (
          <li key={item.text} className={itemClasses} onClick={() => onClick?.(item)}>
            {renderItem
              ? !item.children && renderItem(item)
              : !item.children && <MenuItem {...themableProps} item={item} className={classNames?.menuItem} />}
            {item.children && (
              <CollapsibleBase open={open} onOpenChange={() => setOpen(!open)} className="flex flex-col gap-2">
                <CollapsibleTrigger className={`hover:bg-${color}-hover flex items-center justify-between rounded-lg`}>
                  <MenuItem {...themableProps} item={item} className="gap-2 p-0 px-2 py-1.5" />
                  {open ? <ChevronUp /> : <ChevronDown />}
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <MenuComp<MenuItemRecursive>
                    depth={depth + 1}
                    items={item.children}
                    className={cn(`ml-8 flex flex-col gap-2`)}
                    onClick={onClick}
                    renderItem={renderItem}
                  />
                </CollapsibleContent>
              </CollapsibleBase>
            )}
          </li>
        );
      })}
    </ul>
  );

  if (depth > 0) return menu;

  return (
    <Box noPad {...themableProps} color={UIColors.default} className={cn(cname, classNames?.box)}>
      {menu}
    </Box>
  );
}

export const Menu = React.forwardRef<HTMLUListElement, MenuProps>(
  MenuComp
) as React.ForwardRefExoticComponentExtended<MenuProps>;

export const MenuColors = [UIColors.default, UIColors.primary, UIColors.secondary, UIColors.accent];
