'use client';

import React from 'react';
import { type HorVerLayout, LayoutTypes, type UIThemableComponent } from '@wolfejs/ui/types/UI';
import { cn } from '../utils/classnames';
import { Label } from './Label';

export type FormGroupProps = UIThemableComponent & {
  fieldName?: string;
  label?: React.ReactNode;
  htmlFor?: string;
  layout?: HorVerLayout;
  showRequiredIndicator?: boolean;
  hideTopLabel?: boolean;
};

export const FormGroup = ({
  cname = 'ui-formgroup',
  children,
  label,
  layout,
  fieldName,
  className,
  classNames,
  showRequiredIndicator,
  hideTopLabel = false,
  ...otherProps
}: FormGroupProps) => {
  return (
    <div className={cn(cname, { 'flex-col': layout === LayoutTypes.horizontal }, className)} {...otherProps}>
      {!hideTopLabel && (
        <Label
          htmlFor={fieldName}
          className={cn('mb-1 mr-2 block', classNames?.label, { [`after:content-['*']`]: showRequiredIndicator })}
        >
          {label}
        </Label>
      )}
      {children}
    </div>
  );
};
