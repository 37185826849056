'use client';

import type { UIComponentProps } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';

export type LabelProps = UIComponentProps<HTMLLabelElement> & {
  htmlFor?: string;
  disabled?: boolean;
};

export const Label = ({ className, disabled, htmlFor, children, ...otherProps }: LabelProps) => {
  return (
    <label
      htmlFor={htmlFor}
      {...otherProps}
      className={cn({ 'cursor-pointer': !disabled }, 'text-sm font-medium text-gray-900 dark:text-white', className)}
    >
      {children}
    </label>
  );
};
