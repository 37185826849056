'use client';

import React from 'react';
import type { NumberFormatValues, NumericFormatProps } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import { Input, type InputProps } from './Input';

export type InputNumberProps = InputProps & {
  ref?: React.RefObject<HTMLInputElement> | React.RefCallback<HTMLInputElement>;

  formatProps?: NumericFormatProps;
  // eslint-disable-next-line
  onFormattedValueChange?(value: NumberFormatValues): void;
};

const InputNumberComp = (
  { onValueChange, onFormattedValueChange, formatProps = {}, value, ...otherProps }: InputNumberProps,
  forwardedRef: React.ForwardedRef<HTMLInputElement>
) => {
  // use thousands separator by default
  if (formatProps.thousandSeparator === undefined) formatProps.thousandSeparator = true;
  // also pass the placeholder to the NumberFormat component
  formatProps.placeholder = otherProps.placeholder;
  formatProps.disabled = otherProps.disabled;

  const handleValueChange = React.useCallback(
    (values: NumberFormatValues) => {
      onFormattedValueChange?.(values);
      onValueChange?.(values.value);
    },
    [onFormattedValueChange, onValueChange]
  );

  return (
    <Input
      {...otherProps}
      ref={forwardedRef}
      customInput={() => {
        return (
          <NumericFormat
            value={value}
            className="w-full border-none bg-transparent p-0 outline-none"
            onValueChange={handleValueChange}
            // thousandsGroupStyle="lakh"
            type="text"
            displayType="input"
            // @ts-ignore : using ref here works but seems undefined in the react-number-format-typing
            {...formatProps}
          />
        );
      }}
    />
  );
};

export const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  InputNumberComp
) as React.ForwardRefExoticComponentExtended<InputNumberProps>;
