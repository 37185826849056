'use client';

import * as SliderPrimitive from '@radix-ui/react-slider';
import * as React from 'react';
import type { SliderProps as OriginalSliderProps } from '@radix-ui/react-slider';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
import type { UIThemableComponent } from '../types/UI';
import { UIColors, UIShapesExtra, UISizes, UIVariants } from '../types/UI';
import { cn } from '../utils/classnames';

export type SliderElement = HTMLSpanElement;
export type SliderProps = OriginalSliderProps & UIThemableComponent;

const SliderComp = (
  {
    className,
    color = UIColors.default,
    size = UISizes.md,
    variant = UIVariants.solid,
    shape = UIShapesExtra.pill,
    gradient,
    colorShadows,
    elevation,
    ...props
  }: SliderProps,
  ref: React.ForwardedRef<SliderElement>
) => {
  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, theme, gradient, elevation };

  return (
    <SliderPrimitive.Root
      ref={ref}
      className={cn(
        'relative flex w-full touch-none select-none items-center',
        UIStyling.shapes(themableProps),
        UIStyling.elevations(themableProps),
        UIStyling.disabled(themableProps),
        UIStyling.focusVisibleRing(themableProps),
        colorShadows && UIStyling.colorShadows(themableProps),
        // Sizes
        {
          ['h-1']: size === UISizes.xs,
          ['h-2']: size == UISizes.sm,
          ['h-3']: size == UISizes.md,
          ['h-4']: size == UISizes.lg,
          ['h-5']: size == UISizes.xl,
        },
        className
      )}
      {...props}
    >
      <SliderPrimitive.Track
        className={cn(
          'relative w-full grow overflow-hidden',
          UIStyling.shapes(themableProps),
          UIStyling.variants(themableProps),
          { [`bg-${color}-400`]: variant === UIVariants.solid },
          // Sizes
          {
            ['h-1']: size === UISizes.xs,
            ['h-2']: size == UISizes.sm,
            ['h-3']: size == UISizes.md,
            ['h-4']: size == UISizes.lg,
            ['h-5']: size == UISizes.xl,
          }
        )}
      >
        <SliderPrimitive.Range
          className={cn('absolute h-full', UIStyling.variants(themableProps), {
            [`bg-${color}-400`]: variant === UIVariants.outline,
          })}
        />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb
        className={cn(
          'block shadow transition-colors disabled:pointer-events-none disabled:opacity-50',
          UIStyling.variants(themableProps),
          UIStyling.focusVisibleRing(themableProps),
          UIStyling.shapes(themableProps),
          { [`bg-${color}`]: variant === UIVariants.outline },
          // Sizes
          {
            ['h-2 w-2']: size === UISizes.xs,
            ['h-3 w-3']: size == UISizes.sm,
            ['h-4 w-4']: size == UISizes.md,
            ['h-5 w-5']: size == UISizes.lg,
            ['h-6 w-6']: size == UISizes.xl,
          }
        )}
      />
    </SliderPrimitive.Root>
  );
};

export const Slider = React.forwardRef<SliderElement, SliderProps>(
  SliderComp
) as React.ForwardRefExoticComponentExtended<SliderProps>;

export const SliderVariants = [UIVariants.solid, UIVariants.outline, UIVariants.unstyled];
