'use client';

import React from 'react';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
import type { UIThemableComponent } from '../types/UI';
import { UIColors, UIShapes, UISizes, UIVariants } from '../types/UI';
import { cn } from '../utils/classnames';

export type BoxProps = UIThemableComponent & {
  ref?: React.ForwardedRef<HTMLDivElement>;

  noPad?: boolean;
  fullWidth?: boolean;
};

const BoxComp = (
  {
    cname = 'ui-box',
    children,
    className,

    // ----- Themable props defaults
    color = UIColors.default,
    size = UISizes.md,
    variant = UIVariants.solid,
    shape = UIShapes.rounded,
    colorShadows,
    gradient,
    elevation,

    fullWidth,
    noPad,

    ...otherProps
  }: BoxProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) => {
  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, theme, gradient, elevation };

  return (
    <div
      ref={forwardedRef}
      {...themableProps}
      {...otherProps}
      className={
        variant === UIVariants.unstyled
          ? cn(cname, className)
          : cn(
              cname,
              'inline-block',
              // ----- Add standard set of styling classes
              UIStyling.variants(themableProps),
              UIStyling.shapes(themableProps),
              UIStyling.textSizes(themableProps),
              UIStyling.elevations(themableProps),
              colorShadows && UIStyling.colorShadows(themableProps),
              noPad ? {} : UIStyling.boxPaddings(themableProps),
              {
                [`text-${color}-foreground bg-gradient-to-tr from-${color}-400 via-${color}-300 to-${color}-200`]:
                  variant === UIVariants.solid && gradient,
              },
              { ['block']: fullWidth },
              className
            )
      }
    >
      {children}
    </div>
  );
};

export const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  BoxComp
) as React.ForwardRefExoticComponentExtended<BoxProps>;
// Box.displayName = 'Box';
