'use client';

import React from 'react';
import type {
  ActionMeta,
  ClassNamesConfig,
  GroupBase,
  MultiValue,
  OptionProps,
  Props,
  SingleValue,
  SingleValueProps,
  StylesConfig,
  OnChangeValue as _OnChangeValue,
} from 'react-select';
import ReactSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
import { UIColorContext, UIShapes, UISizes, UIVariants, type UISize, type UIThemableComponent } from '../types/UI';
import { cn } from '../utils/classnames';

const Option = components.Option;
const SingleValueComp = components.SingleValue;

export type { GroupBase, InputActionMeta, MultiValue, OptionsOrGroups, SingleValue } from 'react-select';

export type OnChangeValue<Option, IsMulti extends boolean = false> = _OnChangeValue<Option, IsMulti>;

export type SelectValueType = string | number | readonly string[] | undefined;

export type SelectOption<ValueType = SelectValueType> = {
  label: React.ReactNode;
  size?: UISize;
  icon?: React.ReactNode;
  value?: ValueType | undefined;
  disabled?: boolean;
};

export type SelectProps<
  Option extends SelectOption = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Omit<UIThemableComponent, 'ref'> &
  Props<Option, IsMulti, Group> & {
    cname?: string;
    options?: Option[];
    value?: Option | null;
    initialValue?: Option;
    autoComplete?: string;
    classNameIcon?: string;
    dropdownWidth?: string;
    containWidth?: boolean;
    showIconInSelection?: boolean;
    size?: UISize;
    open?: boolean;
    autoWidth?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    isCreatable?: boolean;
    fixedPosition?: boolean;
    onChange?: (value: Option | null, reason: ActionMeta<Option>) => void;
    onClear?: () => void;
    prepend?: React.ReactNode;
  };

function SelectComp<
  Option extends SelectOption = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  cname = 'ui-select',
  className,

  // ----- Options
  autoWidth,
  fullWidth,
  isCreatable,
  fixedPosition,
  disabled,
  showIconInSelection,
  dropdownWidth,

  // ----- Select props
  open,

  // ----- Themable props defaults
  color = UIColorContext.default,
  variant = UIVariants.solid,
  shape = UIShapes.rounded,
  size = UISizes.md,
  gradient,
  elevation,
  classNames,
  prepend,

  onChange,
  ...otherProps
}: SelectProps<Option, IsMulti, Group>) {
  const [isOpen, setIsOpen] = React.useState<boolean>();

  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, gradient, elevation, theme };

  const handleChange = React.useCallback(
    (value: MultiValue<Option> | SingleValue<Option> | null, reason: ActionMeta<Option>) => {
      if (reason?.action === 'clear') {
        return onChange?.(null, reason);
      }

      onChange?.(value as SingleValue<Option>, reason);
    },
    [onChange]
  );

  let styles: StylesConfig<Option, IsMulti, Group> = {
    menu: (base: object) => ({
      ...base,
      width: dropdownWidth || (autoWidth ? 'max-content' : '100%'),
    }),
    option: (base: object) => ({ ...base, cursor: 'pointer' }),
  };

  if (autoWidth) {
    styles = {
      ...styles,
      menu: (base: object) => ({
        ...base,
        width: 'max-content',
      }),
    };
  }

  if (fixedPosition) {
    styles = {
      menuPortal: (base: object) => ({ ...base, zIndex: 9999 }),
      ...styles,
    };
  }

  const handleMenuOpen = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleMenuClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const _classNames: ClassNamesConfig<Option, IsMulti, Group> =
    variant === UIVariants.unstyled
      ? {}
      : {
          container: () =>
            cn(
              { ['w-full']: fullWidth },
              { [`bg-default-50 dark:bg-default-700`]: variant === UIVariants.solid },
              { [`bg-none`]: variant === UIVariants.outline }
            ),
          menuList: () => cn({ ['bg-white dark:bg-default-800']: variant === UIVariants.outline }),
          menu: () =>
            cn(
              'min-w-full',
              {
                'bg-white dark:bg-default-800 left-[-1px] box-content border border-default-300 dark:border-default-600 border-t-0':
                  theme === 'flowbite',
              },
              {
                [`border-${color}-300 dark:${color}-600`]: theme === 'flowbite' && variant === UIVariants.outline,
              },
              classNames?.menu
            ),
          menuPortal: () => 'z-select',
          placeholder: () => cn({ [`text-default-900 dark:text-white`]: theme === 'flowbite' }),
          option: state =>
            cn(
              `flex items-center px-2 py-2`,
              {
                // Background, text colors, hover state
                [`bg-default hover:bg-default-hover`]: variant === UIVariants.solid && theme !== 'flowbite',
                [`text-default-900 dark:text-white`]: variant === UIVariants.solid && theme === 'flowbite',
                [`hover:bg-${color}-hover hover:text-white`]:
                  theme === 'flowbite' && (variant === UIVariants.solid || variant === UIVariants.outline),
                [`text-default-foreground dark:text-default-300`]:
                  theme === 'flowbite' && state.isSelected && variant === UIVariants.outline,
              },
              {
                // Selected option state
                [`bg-default-active`]: state.isSelected && theme !== 'flowbite',
                [`bg-${color}-active dark:bg-${color}-active text-white`]: state.isSelected && theme === 'flowbite',
                [`text-white`]: theme === 'flowbite' && state.isSelected && variant === UIVariants.outline,
              }
            ),
          control: () =>
            cn('bg-transparent', { 'ml-2 mr-1': theme !== 'flowbite' }, { 'min-h-fit': theme === 'flowbite' }),
          valueContainer: () => cn('cursor-pointer', { 'p-2.5': theme === 'flowbite' }),
          singleValue: () => cn('flex items-center', { [`text-default-900 dark:text-white`]: theme === 'flowbite' }),
          indicatorsContainer: () =>
            cn({
              [`text-default-500 px-2.5`]: theme === 'flowbite',
            }),
        };
  const selectProps = {
    menuIsOpen: open,
    classNamePrefix: cname,
    components: {
      SingleValue: (props: SingleValueProps<Option>) => {
        const { children, data, ...otherProps } = props;

        return (
          <SingleValueComp data={data} {...otherProps}>
            {showIconInSelection && <div className="h-6 w-6">{data.icon}</div>}
            <span className="ml-2 mt-[1px] flex items-center gap-2">
              {prepend}
              {children}
            </span>
          </SingleValueComp>
        );
      },
      Option: (props: OptionProps<Option>) => {
        const { children, data, ...otherProps } = props;

        return (
          <Option data={data} {...otherProps}>
            {data.icon && <div className="h-6 w-6">{data.icon}</div>}
            <div className="mx-2">{children}</div>
          </Option>
        );
      },
    },
    className:
      variant === UIVariants.unstyled
        ? cname
        : cn(
            cname,
            'inline-block',
            UIStyling.shapes(themableProps),
            UIStyling.textSizes(themableProps),
            UIStyling.elevations(themableProps),
            UIStyling.focusVisibleRing(themableProps),
            { disabled },
            { ['border-none']: variant !== UIVariants.outline && theme !== 'flowbite' },
            { 'rounded-b-none': open || isOpen }, // apply open theming if open is true but the container is not focused
            {
              [`border border-default-300 dark:border-default-600 dark:placeholder-default-400`]:
                theme === 'flowbite' && (variant === UIVariants.outline || variant === UIVariants.solid),
              // Outline Borders
              [`border-${color}-300 dark:border-${color}-600`]: theme === 'flowbite' && variant === UIVariants.outline,
            },
            className
          ),
    classNames: _classNames,
    unstyled: true,
    isDisabled: disabled,
    menuPortalTarget: fixedPosition ? document.body : undefined,
    ...otherProps,
    styles,
    onChange: handleChange,
  };

  if (isCreatable) {
    return <CreatableSelect {...selectProps} />;
  }

  return <ReactSelect onMenuOpen={handleMenuOpen} onMenuClose={handleMenuClose} {...selectProps} />;
}

export const Select = SelectComp;

export const SelectColors = [UIColorContext.default, UIColorContext.primary, UIColorContext.secondary];
