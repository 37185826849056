'use client';

import type { UIComponentProps } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';
import type { Language } from 'prism-react-renderer';
import { Highlight, themes } from 'prism-react-renderer';
import React from 'react';

export type CodeBlockProps = Omit<UIComponentProps, 'ref'> & {
  value?: unknown;
  wrap?: boolean;
  title?: React.ReactNode;
  children?: React.ReactElement | React.ReactNode;
  language?: Language;
};

export function CodeBlock({
  cname = 'ui-codeblock',
  children,
  title,
  wrap,
  className: classes,
  value,
  language = 'javascript',
}: CodeBlockProps) {
  const code = React.useMemo(() => {
    try {
      if (value !== undefined && value !== null) {
        if (typeof value === 'string') return value;
        if (typeof value === 'number') return value.toString();
        return JSON.stringify(value, null, 2);
      }
    } catch (err) {
      // silent fail
    }

    return ((children as React.ReactElement)?.props?.children as string)?.trim() || '';
  }, [children, value]);

  return (
    <Highlight theme={themes.dracula} code={code} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <div className={cn(cname, 'not-prose flex min-h-full overflow-auto ')}>
          {title && (
            <span
              className="inline-block rounded-t px-2 py-1 text-sm text-white"
              style={{ background: 'rgb(40, 42, 54)' }}
            >
              {title}
            </span>
          )}
          <pre
            className={cn(className, classes, { 'whitespace-pre-wrap break-all': wrap })}
            style={{ ...style, padding: '20px' }}
          >
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })} key={i}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} key={key} />
                ))}
              </div>
            ))}
          </pre>
        </div>
      )}
    </Highlight>
  );
}
