'use client';

import { formatDate } from '@wolfejs/core/utils/date';
import { ArrowLeft, ArrowRight, CalendarWeek } from '@wolfejs/icons/IconMap';
import DatePickerProvider, {
  DaySlots,
  Header,
  TCalendar,
  TDatePickerProps,
  TDay,
  Title,
  WeekDays,
} from 'headless-react-datepicker';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Label } from '../Form';
import { Input } from '../Input';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import type { UIThemableComponent } from '../types/UI';
import { UIColorContext, UIColors, UIVariants } from '../types/UI';
import { cn } from '../utils/classnames';
import { withRef } from '../utils/withRef';

export type DatePickerProps = UIThemableComponent &
  Omit<TDatePickerProps, 'isRange'> & {
    name?: string;
    isTitle?: boolean;
    isHeader?: boolean;
    prevMonthIcon?: ReactNode;
    nextMonthIcon?: ReactNode;
    renderWithoutWrapper?: boolean;
    withoutInput?: boolean;
    className?: string;
    inputIcon?: ReactNode;
    isRange?: boolean;
    initialValue?: Date;
    calendar?: TCalendar;
    locale?: string;
    weekStartsOn?: TDay;
    weekendSelectable?: boolean;
    maxDate?: Date;
    minDate?: Date;
    dayFormat?: 'numeric' | '2-digit' | undefined;
    weekdayFormat?: 'long' | 'short' | 'narrow' | undefined;
    customClasses?: {
      weekDays?: string;
      wrapper?: string;
      slot?: string;
      selectedDay?: string;
      monthSelect?: string;
      yearSelect?: string;
    };
    label?: string;
    keepLabelSpace?: boolean;
    onChange?: (value: Date | Date[]) => void;
  };
export const DatePickerComponent = ({
  name,
  color = UIColors.primary,
  withoutInput = false,
  isTitle = false,
  isHeader = true,
  renderWithoutWrapper = false,
  isRange,
  prevMonthIcon = <ArrowLeft className="fill-default" />,
  nextMonthIcon = <ArrowRight className="fill-default" />,
  initialValue,
  calendar = 'gregory',
  locale = 'en-US',
  weekStartsOn = 'sunday',
  weekendSelectable = true,
  dayFormat,
  weekdayFormat,
  maxDate,
  minDate,
  inputIcon,
  customClasses,
  onChange,
  label,
  keepLabelSpace = false,
  className = '',
  size,
}: DatePickerProps) => {
  const datePickerRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [value, setValue] = useState<Date>(initialValue || new Date());
  const [rangeValue, setRangeValue] = useState<Date[]>(initialValue && isRange ? [initialValue] : [new Date()]);

  const toggleDatePicker = () => setIsVisible(prevState => !prevState);

  const handleChange = useCallback(
    async (newValue: Date | Date[]) => {
      if (newValue instanceof Date) {
        setValue(newValue);
        toggleDatePicker();
        onChange?.(newValue);
      } else {
        setRangeValue([newValue as unknown as Date]);
      }
    },
    [value, onChange]
  );

  const handleClickOutside = (event: MouseEvent | FocusEvent | TouchEvent) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
      toggleDatePicker();
    }
  };

  useOnClickOutside(datePickerRef, handleClickOutside);

  useEffect(() => {
    initialValue instanceof Date && setValue(initialValue);
  }, [initialValue]);

  const args = {
    calendar,
    config: {
      locale,
      dayFormat,
      weekdayFormat,
      weekStartsOn,
      weekends: ['saturday', 'sunday'] as TDay[],
      weekendSelectable,
      ...(minDate ? { minDate } : {}),
      ...(maxDate ? { maxDate } : {}),
    },
  };

  const datepicker = (
    <DatePickerProvider {...args} onChange={handleChange} initialValue={value}>
      {isTitle && <Title />}
      {isHeader && (
        <Header
          leftIcon={prevMonthIcon}
          rightIcon={nextMonthIcon}
          monthSelectClassName={cn(
            `${
              customClasses?.monthSelect ||
              'text-sm text-default-900 dark:text-white bg-white dark:bg-default-700 font-semibold py-2.5 px-2.5'
            }`
          )}
          yearSelectClassName={cn(
            `${
              customClasses?.yearSelect ||
              'text-sm text-default-900 dark:text-white bg-white dark:bg-default-700 font-semibold py-2.5 px-2.5'
            }`
          )}
        />
      )}
      <WeekDays
        className={cn(
          `dow text-default-500 dark:text-default-400 h-6 text-center text-sm font-medium leading-6 ${
            customClasses?.weekDays || ''
          }`
        )}
      />
      <DaySlots
        slotClassName={cn(
          `p-0 py-1 hover:bg-default-100 dark:hover:bg-default-600`,
          `block flex-1 leading-9`,
          `border-0 rounded-lg cursor-pointer text-center`,
          `text-default-900 dark:text-white font-semibold text-sm`,
          `${customClasses?.slot || ''}`
        )}
        selectedClassName={cn(
          `bg-${color}-700 text-white dark:bg-${color}-600`,
          `hover:bg-${color}-700 hover:text-white hover:dark:bg-${color}-600`,
          `${customClasses?.selectedDay || ''}`
        )}
      />
    </DatePickerProvider>
  );

  return (
    <div className={cn(`relative flex flex-col ${className}`)}>
      {!withoutInput && (
        <div className="flex flex-1 flex-col">
          {keepLabelSpace && (
            <Label htmlFor={name} className={cn('mb-1 mr-2 block')}>
              {label}
            </Label>
          )}
          <Input
            value={rangeValue.length === 2 ? formatDate(rangeValue[0]) : formatDate(value)}
            prepend={inputIcon || <CalendarWeek className={cn(`text-default-500 dark:text-default-400 h-4 w-4`)} />}
            name={name}
            className={cn(
              `border-default-300 text-default-900 dark:border-default-600 dark:placeholder-default-400 dark:bg-default-700 border text-sm dark:text-white`
            )}
            onFocus={toggleDatePicker}
            size={size}
          />
        </div>
      )}
      {renderWithoutWrapper
        ? isVisible && datepicker
        : isVisible && (
            <div
              ref={datePickerRef}
              className={cn(
                customClasses?.wrapper
                  ? customClasses.wrapper
                  : 'dark:bg-default-700 z-modal absolute top-[56px] inline-block rounded-lg bg-white p-4 shadow-lg'
              )}
            >
              {datepicker}
            </div>
          )}
    </div>
  );
};

export const DatePicker = withRef(DatePickerComponent);
export const DatePickerColors = [UIColorContext.default, UIColorContext.primary, UIColorContext.secondary];
export const DatePickerVariants = [UIVariants.solid];
