'use client';

import React from 'react';
import type { RefCallBack } from 'react-hook-form';
import { useAutoFocus } from '../hooks/useAutoFocus';
import { useFocusState } from '../hooks/useFocusState';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
import { UIColorContext, UIShapes, UISizes, UIVariants, type UIThemableComponent } from '../types/UI';
import { cn } from '../utils/classnames';

// <div className="p-2.5" />

export type TextareaProps = UIThemableComponent & {
  ref?: React.RefObject<HTMLTextAreaElement> | RefCallBack | null;

  // ----- textarea props
  name?: string; // textarea element name attribute
  placeholder?: string; // textarea element placeholder attribute
  disabled?: boolean; // textarea element disabled attribute

  // ----- Values
  value?: string; // controlled value
  initialValue?: string; // uncontrolled value
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onValueChange?: (value: string) => void;
  rows?: number;
  // ----- Options
  allowResize?: boolean; // Disable builtin browser resize
  fullWidth?: boolean;
  autoFocus?: boolean; // auto focus the input field when the component mounts

  /**
   * Automatically size the height according to the text content
   */
  autogrow?: boolean;

  /**
   * TabIndex that is passed to the main DOM element.
   * Set this to -1 to exclude this element from the index
   */
  tabIndex?: number;

  // ----- Callbacks
  onBlur?: (ev: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (ev: React.FocusEvent<HTMLTextAreaElement>) => void;
};

function TextareaComp(
  {
    className,

    // Themable props defaults
    color = UIColorContext.default,
    variant = UIVariants.solid,
    shape = UIShapes.rounded,
    size = UISizes.md,
    gradient,
    elevation,

    // ----- Values
    value,
    initialValue,
    onChange,
    onValueChange,

    // ----- Options
    disabled,
    fullWidth = true,
    allowResize,
    autoFocus,
    autogrow,
    tabIndex,
    rows = 2,

    // ----- callbacks
    onFocus,
    onBlur,
    ...otherProps
  }: TextareaProps,
  forwardedRef: React.ForwardedRef<HTMLTextAreaElement>
) {
  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, theme, gradient, elevation };

  // support for uncontrolled component usage
  const [unControlledValue, setUnControlledValue] = React.useState<string>(initialValue || '');
  const controlled = initialValue === undefined;

  const _tabIndex = React.useMemo(() => {
    if (disabled) return -1;
    return tabIndex;
  }, [disabled, tabIndex]);

  const ref = forwardedRef as React.MutableRefObject<HTMLTextAreaElement>;

  // track focused state
  const { focused, handleFocus, handleBlur } = useFocusState<HTMLTextAreaElement>({ onFocus, onBlur });

  // auto focus the input field if autoFocus is true
  useAutoFocus({ ref, autoFocus });

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = React.useCallback(
    ev => {
      const el = ev.target as HTMLTextAreaElement;
      const newValue = el?.value;
      if (!controlled) setUnControlledValue(newValue);

      onChange?.(ev);
      onValueChange?.(newValue);
    },
    [controlled, onChange, onValueChange]
  );

  const fitVertical = React.useCallback(() => {
    if (!ref) return;

    const el = ref?.current;
    if (!el) return;

    el.style.height = 'auto';
    if (el.scrollHeight) el.style.height = `${el.scrollHeight + 2}px`;
  }, [ref]);

  React.useEffect(() => {
    const el = ref?.current;

    if (autogrow) return fitVertical();

    if (!el) return;
    el.style.height = 'unset';
  }, [autogrow, fitVertical, ref]);

  React.useEffect(() => {
    fitVertical();
  }, [fitVertical, value, unControlledValue]);

  // properties for the inner input element
  const inputProps = {
    value: !controlled ? unControlledValue : value ?? undefined,
    tabIndex: _tabIndex,
    disabled,
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    rows: rows,
    ...otherProps,
    ref: ref,
  };

  return (
    <textarea
      className={
        variant === UIVariants.unstyled
          ? 'ui-textarea'
          : cn([
              'ui-textarea cursor-text bg-transparent outline-none',

              UIStyling.focusRing({ ...themableProps, color: 'info' }, focused),
              UIStyling.focusVisibleRing({ ...themableProps, color: 'info' }),
              UIStyling.shapes(themableProps),
              UIStyling.textSizes(themableProps),
              UIStyling.disabled(themableProps),

              // add theming info classes
              `variant-${variant} color-${color} size-${size} shape-${shape}`,

              // ----- Variants
              {
                [`bg-background border border-${color}`]: variant === UIVariants.solid,
                [`variant-${variant} border border-${color}`]: variant === UIVariants.outline,
                [`variant-${variant} border border-transparent`]: variant === UIVariants.ghost,
              },

              // ----- Sizes
              {
                [`size-xs px-1 py-[2px] text-xs`]: size === UISizes.xs,
                [`size-sm px-1 py-1 text-sm`]: size === UISizes.sm,
                [`size-md  text-sm`]: size === UISizes.md,
                [`size-lg px-2 py-1 text-lg`]: size === UISizes.lg,
                [`size-xl px-2 py-1 text-xl`]: size === UISizes.xl,
              },

              { [`cursor-not-allowed`]: disabled },
              { [`resize-none`]: !allowResize },

              { [`w-full`]: fullWidth },

              theme === 'flowbite' && {
                ['p-2.5']: size === UISizes.md,
                [`bg-default-50 border-default-300 text-default-900 dark:bg-default-700 dark:border-${color} `]:
                  variant === UIVariants.solid,
              },
              className,
            ])
      }
      {...inputProps}
    />
  );
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  TextareaComp
) as React.ForwardRefExoticComponentExtended<TextareaProps>;
